<template>
    <div class="game-detail">
        <div class="bg"></div>
        <Header></Header>
        <div class="game-detail-conten" v-if="is_show">
            <div class="detail-header">
                <el-avatar :size="160" shape="square">
                    <img :src="gameInfo.ico"/>
                </el-avatar>

                <div class="detail-info">
                    <h2>{{gameInfo.game_name}}</h2>
                    <p>{{gameInfo.game_desc}}</p>
                </div>

                <div class="open-game" @click="palyGame()">
                    开始游戏
                </div>
            </div>

            <div class="game-img-list">
                <h1 class="title">游戏截图</h1>

                <ul class="screenshots-main">
                    <li v-for="item in gameList">
                        <img :src="item" alt="">
                    </li>
                </ul>
            </div>

            <div class="game-gift" v-if="gameGiftList.length > 0">
                <h1 class="title">游戏礼包</h1>

                <div class="gift-content">
                    <div class="gift-item" v-for="item in gameGiftList">
                        <div class="gift-info">
                            <h3>{{item.gift_name}}</h3>
                            <p>{{item.desc}}</p>
                        </div>
<!--                        <div class="gift-btn-true">-->
<!--                            已领取-->
<!--                        </div>-->
                        <div class="gift-btn-false" @click="getGift(item.id)">
                            领取
                        </div>
                    </div>
                </div>
            </div>

            <RecommendGame isPadding="true"></RecommendGame>

        </div>
        <LoginDialog :centerDialogVisible="DialogVisible" :gameId="app_id" @childFun="childFun"></LoginDialog>
    </div>
</template>

<script>
import RecommendGame from "@/components/RecommendGame";
import LoginDialog from "@/components/LoginDialog/LoginDialog";
import Header from "@/components/layout/Header";
export default {
    name: "Detail",
    data() {
        return {
            game_id: null,
            gameInfo: [],
            gameList: [],
            game_icon: 'http://app.yunjiwan.com',
            is_show: false,
            app_id: null,
            DialogVisible: false,
            gameGiftList: []
        }
    },
    watch: {
        $route(to, from) {
            this.game_id = to.query.gameId;
            this.gameList = [];
            this.getGameDetail()
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        window.scrollTo(0,0)
        this.game_id = this.$route.query.gameId
        this.getGameDetail()
    },
    methods: {
        // 获取游戏详情信息
        async getGameDetail() {
            this.h5Request({
                url: '/api/h5/detail/id/' + this.game_id + '/'
            }).then(result => {
                if(result.data.code == 0) {
                    result.data.data.ico = this.game_icon + result.data.data.ico
                    this.app_id = result.data.data.app_id
                    for(let item in result.data.data) {
                        if(item.indexOf('game_screen') != -1) {
                            this.gameList.push(this.game_icon + result.data.data[item]);
                        }
                    }
                    this.gameInfo = result.data.data
                    document.title = this.gameInfo.game_name
                    this.is_show = true
                    this.getGameGift()
                }
            })
        },
        // 开始游戏
        palyGame() {
            var token = this.Public.getCookie('token');
            if(token != 'null' && token) {
                var routeData = this.$router.resolve({
                    path: "/play",
                    query: {
                        gameId: this.app_id
                    }
                });
                window.open(routeData.href, '_blank');
                return;
            } else {
                this.DialogVisible = true
            }
        },
        childFun(value) {
            this.DialogVisible = value
        },
        // 获取游戏的礼包
        async getGameGift () {
            await this.h5Request({
                url: "/api/h5/gift/list/?game_id=" + this.app_id
            }).then(result => {
                if(result.data.code == 0) {
                    this.gameGiftList = result.data.data
                }
            })
        },
        getGift(giftId) {
            if(this.$store.state.token != 'null' && this.$store.state.token) {
                this.h5Request({
                    url: '/api/h5/gift/get/?u_token='+this.$store.state.token+'&id='+giftId
                }).then(result => {
                    if(result.data.code == 200) {
                        this.$message.success('礼包领取成功！');
                    } else {
                        this.$message.error('获取游戏信息失败，无法正常进行游戏，请联系管理员！');
                    }
                })
            } else {
                this.DialogVisible = true
            }
        }
    },
    components: {
        RecommendGame,
        LoginDialog,
        Header
    }
}
</script>

<style scoped lang="less">

    .title {
        position: relative;
        font-size: 0.1875rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #151C24;
        box-sizing: border-box;
        padding-left: 0.125rem;
        margin-bottom: .1rem;
    }

    .title::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0.04rem;
        height: 100%;
        background: #FF7431;
        opacity: 1;
        border-radius: 8px;
    }

    .game-detail {
        position: relative;
        width: 100%;
        z-index: 1;

        .bg {
            position: absolute;
            width: 100%;
            height: 0.45833rem;
            background: #000333;
            z-index: -1;
        }

        .game-detail-conten {
            width: 100%;
            z-index: 100;
            box-sizing: border-box;
            padding: 0.15625rem 1.35rem 0 1.35rem;
            z-index: 999;

            .detail-header {
                position: relative;
                display: flex;
                width: 100%;
                height: 1.25rem;
                background: #FFFFFF;
                box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.46);
                border-radius: 16px;
                box-sizing: border-box;
                padding: 0.21rem;

                .el-avatar {
                    height: 0.833rem !important;
                    width: 0.833rem !important;
                    line-height: 0.833rem !important;
                }

                .detail-info {
                    width: calc(100% - 0.833rem);
                    margin-left: 0.21rem;

                    h2 {
                        font-size: 0.21875rem;
                        font-family: Microsoft YaHei;
                        color: #383838;
                    }

                    p {
                        width: 80%;
                        margin-top: 0.167rem;
                        font-size: 0.09375rem;
                        line-height: 28px;
                        font-family: Microsoft YaHei;
                        color: #A1A1A1;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                    }
                }

                .open-game {
                    position: absolute;
                    top: 50%;
                    right: 0.21rem;
                    transform: translate(0, -50%);
                    width: 0.875rem;
                    height: 0.4375rem;
                    line-height: 0.4375rem;
                    background: #FF7431;
                    border-radius: 16px;
                    font-size: 0.167rem;
                    font-family: Microsoft YaHei;
                    color: #FFFFFF;
                    text-align: center;
                    cursor: pointer;
                }
            }

            .game-img-list {
                margin-top: 0.3125rem;
                margin-bottom: 0.3125rem;

                .screenshots-main {
                    display: flex;
                    overflow: auto;

                    li {
                        flex-shrink: 0;
                        width: 1rem;
                        height: auto;
                        margin-right: .12rem;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .screenshots-main li:last-child{
                        margin-right: 0;
                    }
                }
            }

            .gift-content {
                width: 100%;
                .gift-item {
                    position: relative;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0.15625rem 0;
                    border-bottom: 1px solid #FF7431;

                    .gift-info {
                        width: 80%;
                        h3 {
                            font-size: .2rem;
                            font-family: Microsoft YaHei;
                            color: #383838;
                            margin-bottom: .1rem;
                        }
                        p {
                            font-size: 0.167rem;
                            font-family: Microsoft YaHei;
                            color: #A1A1A1;
                        }
                    }

                    .gift-btn-true {
                        position: absolute;
                        right: .21rem;
                        top: .15625rem;
                        width: .6rem;
                        height: .3125rem;
                        line-height: .3125rem;
                        border: 1px solid #FF7431;
                        border-radius: 8px;
                        font-size: .167rem;
                        font-family: Microsoft YaHei;
                        color: #FF7431;
                        text-align: center;
                    }

                    .gift-btn-false {
                        position: absolute;
                        right: .21rem;
                        top: .15625rem;
                        width: .521rem;
                        height: .3125rem;
                        line-height: .3125rem;
                        background: #FF7431;
                        border-radius: 8px;
                        font-size: .167rem;
                        font-family: Microsoft YaHei;
                        color: #FFFFFF;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .gift-item:last-child {
                    border-bottom: none;
                }
            }
        }
    }
</style>